import { Link } from "gatsby";
import React from "react";

function Footer() {
  return (
    <footer className="bg-primary-dark p-10 text-gray-300 text-sm">
      <div className="flex flex-col max-w-4xl mx-auto text-center">
        <div className="flex flex-wrap -mx-6 overflow-hidden md:-mx-2">
          <div className="my-6 px-6 w-full overflow-hidden md:my-2 md:px-2 md:w-1/3">
            <h3 className="font-bold">Product</h3>
            <Link to="/about">For Decision Makers</Link>
            <br />
            <Link to="/about">For Risk Managers</Link>
          </div>

          <div className="my-6 px-6 w-full overflow-hidden md:my-2 md:px-2 md:w-1/3">
            <h3 className="font-bold">Company</h3>
            <Link to="/about">About us</Link>
            <br />
            <Link to="/blog">Blog</Link>
          </div>

          <div className="my-6 px-6 w-full overflow-hidden md:my-2 md:px-2 md:w-1/3">
            <h3 className="font-bold">Contact</h3>
            <address>
              <a href="info@riskydecisions.com">info@riskydecisions.com</a>
              <br />
              <a href="tel:+4521745788">+ 45 2147 5788</a>
            </address>
          </div>
        </div>

        <br />
        <br />
        <p>
          &copy; Blaiser.io {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
